<template>
  <div class="flex flex-col">
    <heroSection class="flex h-screen px-24 bg-gray-200" />
    <div class="px-24">
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo
      impedit cupiditate enim totam facilis, eum earum ipsa deleniti aspernatur
      illo beatae, et dignissimos a non iusto temporibus perspiciatis
      repudiandae cumque.
    </div>
  </div>
</template>

<script>
import heroSection from "@/components/public/hero.vue";
export default {
  name: "home-page",
  components: {
    heroSection,
  },
};
</script>

<style></style>
