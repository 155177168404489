<template>
  <div>
	setting dashoard
  </div>
</template>

<script>
export default {
	name:'setting-dashboard'

}
</script>

<style>

</style>