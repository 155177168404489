<template>
  <div>
	
	<div v-if="loading">
		{{loading}}
	</div>
	<div v-else class="">
		<alert-model :showing="showAdd" :actionName="`Save`" @close="showAdd = false" @action="SubmitOrganization">
			
		<template v-slot:header>
		<h1 class="font-semibold">Add Organization</h1>
		</template>
		<template v-slot:body>
			<form ref="addOganizationForm">
				<div class="flex flex-col space-y-4">
				<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Name
					</span>
					<input type="email" v-model="addModel.name" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Organization Name" />
					</label>

				</div>
				<div class=" flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Domain
					</span>
					<input v-model="addModel.domain" type="domain" name="domain" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Organization Domain" />
					</label>
				</div>
				</div>
			</form>
		
		</template>
		</alert-model>

		<div class="flex justify-between  mb-4 py-2 ">
			<div  class="font-semibold text-lg">Organizations</div>
			<!-- <div>Organization</div> -->
			<div>
				<button @click="showAdd = true"  class="bg-green-200 border border-green-400 py-1 px-4 rounded flex items-center">
					<span class="mr-2">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
</svg>
					</span>
					Add New
				</button>
				</div>
		</div>
		<div class="space-y-4">
		<div v-for="(row,index) in numberRow" :key="index" class="md:flex space-y-3 md:space-y-0 md:space-x-3">
		<div v-for="(col,index1) in numberPerLine" :key="index1" class="w-full md:w-1/4">
			<template v-if="showCol(row, col)">
				<div class="p-4 border border-gray-300  rounded-lg space-y-2 hover:cursor-pointer"  @click="$router.push(`/admin/organization/${organizations[col - 1 + numberPerLine * (row - 1)]._id}`)">
					<div class="flex space-x-2">
						<span>Name:</span>
						<span>{{organizations[col - 1 + numberPerLine * (row - 1)].name}}</span>
					</div>

					<div class="flex space-x-2">
						<span>Domain:</span>
						<span>{{organizations[col - 1 + numberPerLine * (row - 1)].domain}}</span>
					</div>
					<div class="flex space-x-2 justify-between">
						<div>
						<span v-if="organizations[col - 1 + numberPerLine * (row - 1)].status === `Pending`" class="text-red-400">{{organizations[col - 1 + numberPerLine * (row - 1)].status}}</span>
						<span v-if="organizations[col - 1 + numberPerLine * (row - 1)].status === `Verified`" class="text-green-600">{{organizations[col - 1 + numberPerLine * (row - 1)].status}}</span>
						</div>
						<div>
							<button @click.stop="changeStatus(`Verified`,organizations[col - 1 + numberPerLine * (row - 1)]._id)" class="border border-green-200 px-4 py-1 rounded-lg text-sm" v-if="organizations[col - 1 + numberPerLine * (row - 1)].status ==='Pending'">Activate</button>
							<button @click.stop="changeStatus(`Pending`,organizations[col - 1 + numberPerLine * (row - 1)]._id)" class="border border-red-200 px-4 py-1 rounded-lg text-sm" v-else>De-activate</button>
							
						</div>
					</div>	
				</div>
				
			</template>
		</div>
			
		</div>
		</div>
		<!-- <div class=" border border-gray-500" v-for="(organization ,index) in organizations" :key="index">
			{{organization}}
		</div> -->
		
	</div>
  </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import AlertModel from '@/components/model/alert.vue';
export default {
	name:'organization-page',
	components:{
		AlertModel
	},
	data(){
		return {
			showAdd:false,
			loading:true,
			numberPerLine: 4,
			addModel:{
				name:'',
				domain:''
			}
		}
	},
	async created(){
		this.loading = true;
		await this.doGet();
		this.loading = false;
	},
	computed:{
		...mapGetters({
            message:'OrganizationStore/message',
            error:'OrganizationStore/error',
            color:'OrganizationStore/color',
			organizations:'OrganizationStore/organizations',
        }),
	numberRow() {
      return Math.ceil(this.organizations.length / this.numberPerLine);
    },
	},
	methods:{
		...mapActions({
            doGet:'OrganizationStore/doGet',
			doCreate:'OrganizationStore/doCreate',
			doChangeStatus:'OrganizationStore/doChangeStatus',
        }),
		async changeStatus(status,id){
			let payload ={
				id:id,
				data:{
					status:status
				}
			}
			// console.log("payload =>"+JSON.stringify(payload));
			await this.doChangeStatus(payload)
			
		},
		async SubmitOrganization(){
			console.log("addModel =>"+this.addModel);
			await this.doCreate(this.addModel)
			this.$refs.addOganizationForm.reset();
			this.showAdd = false,
			console.log('====================================');
			console.log(this.error );
			console.log(this.message );
			console.log('====================================');
			await this.$notify({
                type:this.error ? "error":"success",
                title:this.message
            })
		},
		showCol(row, col) {
      var total = col - 1 + this.numberPerLine * (row - 1);

      if (total < this.organizations.length) {
        return true;
      } else {
        return false;
      }
    },

	}

}
</script>

<style>

</style>