<template>
  <div
    class="bg-primary text-white px-24 py-4 flex justify-between navigation fixed top-0 left-0 w-full"
  >
    <div class="flex space-x-8">
      <div>
        <router-link to="/"> Adey Mailer </router-link>
      </div>
      <ul class="flex space-x-4"></ul>
    </div>
    <div class="flex space-x-6">
      <router-link to="/service">Services</router-link>
      <router-link to="/price">Pricing</router-link>
      <router-link to="/contact">Contact Us</router-link>
    </div>
    <div>
      <router-link to="/login" class="bg-btn-primary px-8 py-2 rounded">
        Login
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "top-header",
};
</script>

<style></style>
