<template>
  <div class="flex flex-col px-4">
	<div class="flex justify-between my-4">
		<alert-model  :showing="showModal" :actionName="`Save`" @close="showModal = false" @action="SubmitHost">
			<template v-slot:header>
			<h1 class="font-semibold">Add Host</h1>
			</template>
					<template v-slot:body>
			<form ref="addHostForm">
			<div class="flex flex-col space-y-4">
					<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Host Name
					</span>
					<input type="email" v-model="hostSchema.name" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Host Name" />
					</label>

				</div>

				<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Username
					</span>
					<input type="email" v-model="hostSchema.username" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Username" />
					</label>

				</div>
			
				<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Password
					</span>
					<input type="password" v-model="hostSchema.password" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Password" />
					</label>

				</div>
			</div>
			</form>
		</template>
		</alert-model>
		<span class="font-semibold text-xl">Hosts</span>
		<button @click="showModal = true" class="flex bg-green-200 border border-green-400 py-1 px-4 rounded-lg items-center">
			<span class="mr-2">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
			<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
			</svg>
			</span>
			<span>Add New</span>
		</button>
		
	</div>
	
	<div class="flex flex-col space-y-2">
		<div v-for="(host , index) in hosts" :key="index" class="border border-gray-300 py-4 px-10 rounded-lg flex justify-between items-center">
			<div class="flex flex-col ">
				<div class="mb-2">
					<span class="text-xl font-semibold">Host name: </span>
					<span class="text-xl ">{{host.name}} </span>
				</div>
				<div>
					<span class="text-xl font-semibold">User name: </span>
					<span class="text-xl ">{{host.username}}</span>
				</div>
				
			</div>
			<div class="">
				{{host.status}}
			</div>
			
		</div>
	</div>
	
  </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import AlertModel from '@/components/model/alert.vue'
export default {
	name:'host-tab',
	components:{
		AlertModel
	},
	data(){
		return {
			showModal:false,
			hostSchema:{
				name:'',
				username:'',
				password:''
			}
		}
	},
		computed:{
		...mapGetters({
            message:'HostStore/message',
            error:'HostStore/error',
            color:'HostStore/color',
			hosts:'HostStore/hosts',
        }),
	},
	async created(){
		await this.doGetAll(this.$route.params.organizationId)		
	},
	methods:{
		...mapActions({
			doGetAll:'HostStore/doGetAll',
			doCreate:'HostStore/doCreate',
		}),
		async SubmitHost(){
			console.log("$route.params =>"+ JSON.stringify(this.$route.params.organizationId) );
			let payload={
				id:this.$route.params.organizationId,
				data:this.hostSchema
			}
			await this.doCreate(payload)
			this.$refs.addHostForm.reset();
			this.showModal = false

			await this.$notify({
                type:this.error ? "error":"success",
                title:this.message
            })
		}
	}
}
</script>

<style>

</style>