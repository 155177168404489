import axios from "axios";
import Api from "../../services/api"
export default {
	namespaced: true,
	state: {
		emails: []
	},
	actions: {
		async doGetAll({ commit }) {
			let response;
			try {
				response = await axios.get('https://telemedicine-cms.danielmelesse.de/subscriptions')
				// console.log("response =>" + response);
				if (response.data.length !== 0) {
					commit('SET_EMAILS', response.data)
				}
			} catch (err) {
				// console.log('error =>' + err);
			}
		},

		async sendMessage({ commit }, payload) {
			// console.log({ ...payload });
			let response;
			try {
				response = await Api().post(`/organization/send`, { ...payload })
				// console.log("response =>" + JSON.stringify(response));
				commit('SET_MESSAGE', response.data.message)
				commit('SET_ERROR', response.data.error)

			} catch (e) {
				// console.log("error =>" + e);
			}

		}

	},
	getters: {
		SideBarMini(state) {
			return state.sidebarMin
		},
		emails(state) {
			return state.emails
		}
	},
	mutations: {
		SET_EMAILS(state, payload) {
			state.emails = payload
		},
		TOGGLE_SIDEBAR_MINI(state) {
			state.sidebarMin = !state.sidebarMin
		}
	}
}