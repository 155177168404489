export default {
	namespaced: true,
	state: {
		open: true,
		message: 'dhjbfjvkdfbjkndfk',
		error: false,
		color: '',
	},
	actions: {
		toggleOpen({ commit }) {
			commit('SET_OPEN')
		},
	},
	getters: {
		message(state) {
			return state.message
		},
		error(state) {
			return state.error
		},
		color(state) {
			return state.color
		},
		open(state) {
			return state.open
		}
	},
	mutations: {
		SET_COLOR(state, color) {
			state.color = color
		},
		SET_ERROR(state, error) {
			state.error = error
		},
		SET_MESSAGE(state, message) {
			state.message = message
		},
		SET_OPEN(state, payload) {
			state.open = payload
		}
	}
}