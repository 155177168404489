<template>
  <div v-if="showing" class="fixed inset-0 w-full h-screen flex items-center justify-center bg-semi-75">
    <div class="bg-white w-1/2  rounded shadow-lg">
      <div class="flex p-4 border-b border-gray-300 justify-between items-center">
        <slot name="header"></slot>
        <span @click="close" class="cursor-pointer text-red-500 rounded-full border border-red-400 p-1">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </span>
      </div>
      <div class="p-4 border-b border-gray-300">
         <slot name="body"></slot>
      </div>
      <div class="p-4 flex justify-end">
        <div class="flex space-x-3">
          <button @click="close" class="border border-gray-200 px-4 py-1 rounded">Cancel</button>
          <button @click="action" class="border border-green-500 px-4 py-1 rounded">{{actionName}}</button>
        </div>
      </div>
      
    </div>
    
  </div>
</template>

<script>
export default {
    name:'alert-section',
      props: {
    showing: {
      required: true,
      type: Boolean
    },
    actionName: {
      required: true,
      default:'Save',
      type: String
    }
  },
  data(){
    return {
      showAlert : false,
    }
  },
  methods:{
    close() {
      this.$emit('close');
    },
    action(){
      this.$emit('action');
    }
  }

}
</script>

<style>

</style>