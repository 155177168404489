<template>
<div class="flex flex-col space-y-3">
	<alert-model  :showing="showModal" :actionName="`Save`" @close="showModal = false" @action="SubmitHost">
			<template v-slot:header>
			<h1 class="font-semibold">Add Host</h1>
			</template>
					<template v-slot:body>
			<form ref="addHostForm">
			<div class="flex flex-col space-y-4">
					<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Host Name
					</span>
					<input type="email" v-model="hostSchema.name" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Host Name" />
					</label>

				</div>

				<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Username
					</span>
					<input type="email" v-model="hostSchema.username" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Username" />
					</label>

				</div>
			
				<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Password
					</span>
					<input type="password" v-model="hostSchema.password" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Password" />
					</label>

				</div>
			</div>
			</form>
		</template>
		</alert-model>
	<div class="flex flex-col space-y-2">
	<div class="flex justify-between items-center">
		<span>Hosts</span>
		<button @click="showModal = true" class="flex border border-gray-400 py-1 px-4 rounded items-center">
			<span class="mr-2">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
			<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
			</svg>
			</span>
			<span>Add New</span>
		</button>
		
		</div>
  <div>
	<div v-if="loading">
		loading
	</div>
	<div v-else>
		<div class="flex flex-col space-y-2">
			<div  v-for="(host,index) in hosts" :key="index" class=" border border-gray-200 p-4 rounded-lg">
					<div class="flex space-x-5">
						<span>Name:{{host.name}}</span>
						<span>Username:{{host.username}}</span>

					</div>
					
			</div>
		</div>
	</div>
  </div>
  </div>
</div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import AlertModel from '@/components/model/alert.vue'
export default {
	name:'api-dashboard',
	components:{
		AlertModel
	},
	data(){
		return {
			loading:true,
			showModal:false,
			hostSchema:{
				name:'',
				username:'',
				password:''
			}
		}
	},	
		async created(){
		this.loading = true;
		await this.doGetHost();
		this.loading = false;
	},
	computed:{
		...mapGetters({
			hosts:'OrganizationStore/hosts',
			message:'OrganizationStore/message',
			error:'OrganizationStore/error',
		})
	},
	methods:{
		...mapActions({
            doGetHost:'OrganizationStore/doGetHost',
			doCreateHost:'OrganizationStore/doCreateHost'
        }),
		async SubmitHost(){
			await this.doCreateHost(this.hostSchema)
			this.$refs.addHostForm.reset();
			this.showModal = false

			await this.$notify({
                type:this.error ? "error":"success",
                title:this.message
            })
		}
	}

}
</script>

<style>

</style>