<template>
  <div class="flex flex-col space-y-2">
    <alert-model  :showing="showModal" :actionName="`Save`" @close="showModal = false" @action="SubmitApi">
			<template v-slot:header>
			<h1 class="font-semibold">Add API</h1>
			</template>
			<template v-slot:body>
				<form ref="addAPIForm">
						<div class="flex flex-col space-y-4">
					<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Host Name
					</span>
					<input type="email" v-model="apiSchema.name" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Name" />
					</label>

				</div>

				<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Endpoint url
					</span>
					<input type="email" v-model="apiSchema.endpoint" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Endpoint url" />
					</label>

				</div>
			
			</div>
				</form>
			</template>
		</alert-model>

    <div class="flex justify-between my-4">
		<span class="font-semibold text-xl">API List</span>
				<button @click="showModal = true" class="flex bg-green-200 border border-green-400 py-1 px-4 rounded-lg items-center">
			<span class="mr-2">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
			<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
			</svg>
			</span>
			<span>Add New</span>
		</button>
	</div>
  
    <div>
    <div v-if="loading">loading</div>
    <div v-else>
      <!-- <toast-component /> -->
      <div class="flex flex-col">
        <div
          v-for="(api, index) in apis"
          :key="index"
          class="border border-gray-200 p-4 rounded-lg"
        >
          {{ api }}
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AlertModel from '@/components/model/alert.vue'
export default {
  name: "api-dashboard",
  components:{
		AlertModel
	},
  data() {
    return {
      loading: true,
      showModal:false,
			apiSchema:{
				name:'',
				endpoint:''
			}
    };
  },
  async created() {
    this.loading = true;
    await this.doGetApi();
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      apis: "OrganizationStore/apis",
      message:'OrganizationStore/message',
			error:'OrganizationStore/error',
    }),
  },
  methods: {
    ...mapActions({
      doGetApi: "OrganizationStore/doGetApi",
      doCreateApi: "OrganizationStore/doCreateApi",
    }),
    async SubmitApi(){
			console.log("smaple data");
			await this.doCreateApi(this.apiSchema)
			this.$refs.addAPIForm.reset();
			this.showModal = false

			await this.$notify({
                type:this.error ? "error":"success",
                title:this.message
            })
		}
  },
};
</script>

<style></style>
