<template>
<div>
  <notifications position="bottom right"/>
  <router-view />
</div>

</template>

<style>

</style>
