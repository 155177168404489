<template>
  <div class="px-10 py-12 flex">
    <div class="flex w-2/3">Login</div>
      <login-section class="flex w-1/3" />
  </div>
</template>

<script>
import LoginSection from "@/components/public/login.vue"
export default {
    name:'login-page',
    components:{
        LoginSection,
    },
    data(){
        return {
          
        }
    }

}
</script>

<style>

</style>