import Api from "../../services/api";
import Role from "@/services/constants";
import router from "@/router/index";
export default {
  namespaced: true,
  state: {
    users: [],
    error: false,
    color: "",
    message: "",
  },
  actions: {
    toggleSideBarMini({ commit }) {
      commit("TOGGLE_SIDEBAR_MINI");
    },
    async login({ commit }, payload) {
      // console.log("payload=>" + JSON.stringify(payload));
      let response;
      try {
        response = await Api().post(`/user/login`, { ...payload });
        // console.log("response =>" + JSON.stringify(response));
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          let localStorageValue = {
            token: response.data.token,
            role: response.data.role,
          };
          localStorage.setItem(
            "loggedInUser",
            JSON.stringify(localStorageValue)
          );

          //
          if (localStorage.loggedInUser) {
            let loggedInUserRole = JSON.parse(localStorage.loggedInUser).role;
            console.log("loggedin role", loggedInUserRole);
            if (loggedInUserRole === Role.OrganizationAdmin) {
              router.replace("/organization");
            } else if (loggedInUserRole === Role.SystemAdmin) {
              router.replace("/admin");
            }
          }
        }
      } catch (e) {
        console.log("eror", e);
      }
    },

    async doGetByOrganizationId({ commit }, payload) {
      let response;
      try {
        response = await Api().get(`/user/organizationId/${payload}`);
        console.log(
          "user by organization response =>" + JSON.stringify(response)
        );
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (!response.data.error) {
          commit("SET_USER", response.data.record);
        }
      } catch (e) {
        console.log("eror", e);
      }
    },
  },
  getters: {
    message(state) {
      return state.message;
    },
    error(state) {
      return state.error;
    },
    color(state) {
      return state.color;
    },
    users(state) {
      return state.users;
    },
  },
  mutations: {
    SET_COLOR(state, color) {
      state.color = color;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_MESSAGE(state, message) {
      state.message = message;
    },
    SET_USER(state, payload) {
      state.users = payload;
    },
  },
};
