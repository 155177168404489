<template>
  <div>
      account
  </div>
</template>

<script>
export default {
    name:'account-page'

}
</script>

<style>

</style>