<template>
  <div>
   
	<div v-if="loading">
		loading
	</div>
	<div v-else class="space-y-2">
    <div class="flex justify-between px">
      <div>API Keys</div>
      <div>
        <button @click="showModal = true" class="flex bg-green-200 border border-green-400 py-1 px-4 rounded-lg items-center">
			<span class="mr-2">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
			<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
			</svg>
			</span>
			<span>Add New</span>
		</button>
      </div>
    </div>
		<div class="flex flex-col">
			<div  v-for="(apikey,index) in apikeys" :key="index" class=" border border-gray-200 p-4 rounded-lg">
				<div class="flex space-x-5">
          <div>Name</div>
          <div>{{apikey.name}}</div>
        </div>
        <div class="flex space-x-5 items-center">
          <div>token</div>
          <div class="w-full flex mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300  focus:outline-none  rounded-md sm:text-sm hover:bg-blue-50">
            <span class="w-full">{{apikey.token}}</span>
            <!-- <input readonly :value="apikey.token" type="email" name="email" class="w-full focus:outline-none" placeholder="Name" /> -->
            <div class="flex space-x-4">
              <button @click="copy(apikey.token)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
              </button>
             
            </div>
        </div>
        </div>
        
			</div>
		</div>
		
	</div>
	
  </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import useClipboard from 'vue-clipboard3'
const { toClipboard } = useClipboard()
export default {
	name:'apikey-dashboard',
	data(){
		return {
			loading:true,
		}
	},	
		async created(){
		this.loading = true;
		await this.doGetApiKey();
		this.loading = false;
	},
	computed:{
		...mapGetters({
			apikeys:'OrganizationStore/apikeys',
		})
	},
	methods:{
		...mapActions({
            doGetApiKey:'OrganizationStore/doGetApiKey',
        }),
       async copy (data){
         
      try {
        await toClipboard(data)
        console.log('Copied to clipboard')
      } catch (e) {
        console.error(e)
      }
    }
	}

}
</script>

<style>

</style>