import Api from "../../services/api"
export default {
	namespaced: true,
	state: {
		templates: []
	},
	actions: {
		async sendMessage({ commit }) {
			let payload = {
				"from": "info@samaritansc.com",
				"to": "alivestay990@gmail.com",
				"subject": "Welcome to Adey meselesh",
				"text": "welcome to our adey meselesh GmbH Software "
			}
			let response;
			try {
				response = await Api().post(`/organization/sendEJS`, { ...payload })
				console.log("response =>" + JSON.stringify(response));
				commit('SET_TEMPLATES', response.data.record)

			} catch (e) {
				console.log("error =>" + e);
			}

		}

	},
	getters: {
		templates(state) {
			return state.templates
		}
	},
	mutations: {
		SET_TEMPLATES(state, payload) {
			state.templates = payload
		},
	}
}