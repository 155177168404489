<template>
<div class="flex flex-col space-y-3">
		<alert-model  :showing="showModal" :actionName="`Save`" @close="showModal = false" @action="SubmitTemplate">
			<template v-slot:header>
			<h1 class="font-semibold">Add Email Template</h1>
			</template>
					<template v-slot:body>
			<form ref="addTemplateForm">
			<div class="flex flex-col space-y-4">
					<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Template Name
					</span>
					<input type="email" v-model="emailTemplate.name" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Host Name" />
					</label>

				</div>

				<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Template Body(html)
					</span>
					<textarea type="email" v-model="emailTemplate.template" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="<html><body></body></html>" />
					</label>

				</div>
			
				
			</div>
			</form>
		</template>
		</alert-model>
	<div class="flex flex-col space-y-4">
		<div class="flex justify-between items-center">
		<span>Templates</span>
		<button @click="showModal = true" class="flex border border-gray-400 py-1 px-4 rounded items-center">
			<span class="mr-2">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
			<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
			</svg>
			</span>
			<span>Add New</span>
		</button>
		
		</div>
		<div v-if="loading">
			loading
		</div>
		<div v-else>
			<div class="flex flex-col space-y-2">
			<div  v-for="(template,index) in templates" :key="index" class=" border border-gray-200 p-4 rounded-lg">
					<div class="flex space-x-5">
						<span>Name:{{template.name}}</span>
						<span>Template:{{template.template}}</span>

					</div>
					
			</div>
		</div>
		

		</div>
	</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AlertModel from '@/components/model/alert.vue'
export default {
	name: 'template-dashboard',
	components:{
		AlertModel
	},
	data() {
		return {
			loading: true,
			showModal:false,
			emailTemplate:{
			name: '',
            template: '',
			}
		}
	},
	async created() {
		this.loading = true
		await this.doGetTemplate()
		this.loading = false
	},
	computed: {
		...mapGetters({templates: "OrganizationStore/templates",message:'OrganizationStore/message',error:'OrganizationStore/error',}),
	},
	methods: {
		...mapActions({doGetTemplate: "OrganizationStore/doGetTemplate",doCreateTemplate: "OrganizationStore/doCreateTemplate",}),
		//
		async SubmitTemplate(){
			await this.doCreateTemplate(this.emailTemplate)
			this.$refs.addTemplateForm.reset();
			this.showModal = false

			await this.$notify({
                type:this.error ? "error":"success",
                title:this.message
            })
		}
		
	}

}
</script>

<style>
</style>