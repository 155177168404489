<template>
  <div class="flex flex-col px-24">contact us</div>
</template>

<script>
export default {
  name: "contact-page",
};
</script>

<style></style>
