<template>
  <div class=" flex flex-col  p-4 space-y-3 w-1/3">
      <form @submit.prevent ="submitLogin" class="space-y-3">
      <div class="text-lg font-semibold">Login</div>
        <div class="flex flex-col space-y-2">
          <label for="email">Email</label>
          <input 
          type="text"
          v-model="loginSchema.email"
          name="name"
          class="w-full px-4 py-2 border border-gray-300 rounded outline-none"
          />
        </div>
         <div class="flex flex-col space-y-2">
          <label for="email">Password</label>
          <input 
          type="password"
          v-model="loginSchema.password"
          name="name"
          class="w-full px-4 py-2 border border-gray-300 rounded outline-none"
          />
        </div>
        <div class="flex w-full ">
            <button type="submit" class="bg-blue-400 w-full py-2 rounded-md" >Login</button>
        </div>
        </form>
  </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
// import Role from '@/services/constants'
export default {
    name:'login-section',
    data:()=>({
        loginSchema:{
            email:'',
            password:''
        }
    }),
    // error: false,
    //     color: '',
    //     message: ''
    computed:{
        ...mapGetters({
            message:'UserStore/message',
            error:'UserStore/error',
            color:'UserStore/color',
        })
    },
    methods:{
        ...mapActions({
            login:'UserStore/login'
        }),
        async submitLogin(){
            await this.login(this.loginSchema)
            await this.$notify({
                type:this.error ? "error":"success",
                title:this.message
            })
            // if(!res.data.error && !this.error){
            //     let localStorageValue = { token: res.data.token, role: res.data.role };
            //     localStorage.setItem("loggedInUser", JSON.stringify(localStorageValue));
            //     await this.redirectUser()
            // }
            // console.log("login button clicked");
            
        },

        // async redirectUser() {
        //     if (localStorage.loggedInUser) {
        //         let loggedInUserRole = JSON.parse(localStorage.loggedInUser).role;
        //         console.log("loggedin role", loggedInUserRole);
        //         if (loggedInUserRole === Role.OrganizationAdmin) {
        //             this.$router.replace(this.$route.query.redirect || "/dashboard");
        //         } else if (loggedInUserRole === Role.SystemAdmin) {
        //             this.$router.replace(this.$route.query.redirect || "/admin");
        //         }
        //     }else{
        //         console.log("no ");
        //     }
        // }
    }

}
</script>

<style>

</style>