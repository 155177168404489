<template>
  <div>
	<router-view />
  </div>
</template>

<script>
export default {
	name:'client-index'

}
</script>

<style>

</style>