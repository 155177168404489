<template>
<div>
	<div v-if="loading">
		loading
	</div>
	<div v-else class="flex flex-col">
		<div class="mb-3">
			<button class="bg-blue-100 border border-blue-200 flex items-center px-3 py-1 rounded-md" @click="$router.go(-1)"> 
				<span class="">
					<svg xmlns="http://www.w3.org/2000/svg" class="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
					<path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
					</svg>
				</span>
				<span class="ml-2">
					Back to list
				</span>
				</button>
		</div>
		
		<div class="bg-blue-50 p-5 rounded border-b border-blue-100">
			<span class="text-2xl ">
				{{organization.name}}
			</span>
			
			
		</div>
		<div class="bg-blue-50 border-b px-5">
			<div class="flex">
				<div class="flex -mb-px mr-8">
					<div  @click="selectTab(0)" :class="activeTab(0) ?`text-blue-500 font-semibold border-b border-blue-300` :`` " class="flex items-center border-b  py-3  hover:cursor-pointer">
					
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5  mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
						<path stroke-linecap="round" stroke-linejoin="round" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
						</svg>
						Dashboard
						
					</div>
				</div>
				<div class="flex -mb-px mr-8">
					<div  @click="selectTab(1)" :class="activeTab(1) ?`text-blue-500 font-semibold border-b border-blue-300` :`` " class="flex items-center border-b py-3  hover:cursor-pointer">
						
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5  mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
						<path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
						</svg>
					
						<span>Contacts</span>
						
					</div>
				</div>
<div class="flex -mb-px mr-8">
					<div @click="selectTab(2)" :class="activeTab(2) ?`text-blue-500 font-semibold border-b border-blue-300` :`` " class="flex items-center border-b py-3  hover:cursor-pointer">
						
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5  mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
						<path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
						</svg>
						<span>Hosts</span>
						
					</div>
				</div>
<!-- admins -->


				<!-- api -->
				<div class="flex -mb-px mr-8">
					<div @click="selectTab(3)" :class="activeTab(3) ?`text-blue-500 font-semibold border-b border-blue-300` :`` " class="flex items-center border-b py-3  hover:cursor-pointer">
						

						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5  mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
						<path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
						</svg>
						<span>API</span>
						
					</div>
				</div>
								<div class="flex -mb-px mr-8">
					<div @click="selectTab(4)" :class="activeTab(4) ?`text-blue-500 font-semibold border-b border-blue-300` :`` " class="flex items-center border-b py-3  hover:cursor-pointer">
						
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
						<span>Admins</span>
						
					</div>
				</div>
				</div>
		</div>
		<div class="">
			<template v-if="selectedIndex ===0">
				<dashboard-tab />
			</template>
			<template v-if="selectedIndex ===1">
				<contact-tab />
			</template>
			<template v-if="selectedIndex ===2">
				<hosts-tab/>
			</template>
			<template v-if="selectedIndex ===3">
				<api-tab/>
			</template>
			<template v-if="selectedIndex ===4">
				<admin-tab/>
			</template>
			
		</div>
		
		
	</div>
</div>

</template>

<script>
import {mapGetters,mapActions} from 'vuex'
// import components
import DashboardTab from './tabs/dashboard.vue'
import ContactTab from "./tabs/contact.vue"
import HostsTab from "./tabs/host.vue"
import ApiTab from "./tabs/api.vue" 
import AdminTab from "./tabs/admin.vue"
export default {
	name:'single-o-page',
	components:{
	ContactTab,
	DashboardTab,
	HostsTab,
	ApiTab,
	AdminTab
	},
	data(){
		return {
			loading:true,
			selectedIndex:0
		}
	},
	mounted () {
      this.selectTab(0)
  },
	async created(){
		this.loading = true
		console.log("$route.params =>"+ JSON.stringify(this.$route.params) );
		await this.doGetOne(this.$route.params.organizationId)
		this.loading = false
	},
	computed:{
		...mapGetters({
            message:'OrganizationStore/message',
            error:'OrganizationStore/error',
            color:'OrganizationStore/color',
			organization:'OrganizationStore/organization',
        }),
	},
	methods:{
		...mapActions({
            doGetOne:'OrganizationStore/doGetOne'
        }),
		activeTab(index){
			return	(this.selectedIndex === index) ? true: false;
		},
		selectTab (i) {
			this.selectedIndex = i
		}
	}
}
</script>

<style>

</style>