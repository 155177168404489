<template>
  <div class="flex flex-col px-4">
	<div class="flex justify-between my-4">
		<alert-model :showing="showModal" :actionName="`Save`" @close="showModal = false" @action="SubmitContact">
			<template v-slot:header>
		<h1 class="font-semibold">Add Contact</h1>
		</template>
		<!-- / form body/ -->
		<template v-slot:body>
			<form ref="addContactForm">
			<div class="flex flex-col space-y-4">
					<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Name
					</span>
					<input type="email" v-model="contactSchema.personName" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Full Name" />
					</label>

				</div>

				<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Email
					</span>
					<input type="email" v-model="contactSchema.email" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Email" />
					</label>

				</div>
			
				<div class="flex flex-col">
					<label class="block">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Phone Number
					</span>
					<input type="email" v-model="contactSchema.phoneNumber" name="email" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Phone Number" />
					</label>

				</div>
			</div>
			</form>
		</template>
		</alert-model>
		<span class="font-semibold text-xl">Contact</span>
		<button @click="showModal = true" class="flex bg-green-200 border border-green-400 py-1 px-4 rounded-lg items-center">
			<span class="mr-2">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
			<path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
			</svg>
			</span>
			<span>Add New</span>
		</button>
	</div>
	<div class="flex flex-col space-y-2">
		<div v-for="(contact,index) in contacts" :key="index" class="border border-gray-300 py-4 px-10 rounded-lg flex justify-between">
			<div>
				Name : 
				<span>{{contact.personName}}</span>
			</div>
			<div>
				<span>Email : </span>
				<span>{{contact.email}}</span>
			</div>
			<div>
				<span>Phone Number : </span>
				<span>{{contact.phoneNumber}}</span>
			</div>
			
		</div>
	</div>
  </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import AlertModel from '@/components/model/alert.vue'
export default {
	name:'contact-tab',
	components:{
		AlertModel
	},
	data(){
		return {
			showModal:false,
			contactSchema:{
				email:'',
				personName:'',
				phoneNumber:''
			}
		}
	},
		computed:{
		...mapGetters({
            message:'ContactStore/message',
            error:'ContactStore/error',
            color:'ContactStore/color',
			contacts:'ContactStore/contacts',
        }),
	},
	async created(){
		await this.doGetAll(this.$route.params.organizationId)		
	},
	methods:{
		...mapActions({
			doGetAll:'ContactStore/doGetAll',
			doCreate:'ContactStore/doCreate',
		}),
		async SubmitContact(){
			console.log("$route.params =>"+ JSON.stringify(this.$route.params.organizationId) );
			let payload={
				id:this.$route.params.organizationId,
				data:this.contactSchema
			}
			await this.doCreate(payload)
			this.$refs.addContactForm.reset();
			this.showModal = false

			console.log(this.error);
			console.log(this.message);

			await this.$notify({
                type:this.error ? "error":"success",
                title:this.message
            })
			
		}
	}
}
</script>

<style>

</style>