import axios from "axios";
import AuthHeader from "./authHeader";

// Returns an axios object
export default () => {
  return axios.create({
    baseURL: "https://aem.adeymeselesh.de/api/v1",
    // baseURL: "http://localhost:3506/api/v1",
    headers: AuthHeader.authHeader(),
  });
};
