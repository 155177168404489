<template>
  <div class="">
    <div class="flex space-x-3">
      <router-link
        to="/organization/email"
        class="border border-gray-200 px-4 py-12 w-1/3 rounded justify-center flex items-center"
      >
        <div class="flex mr-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div class="text-center">
          <h1 class="text-2xl font-bold text-green-900">10,000</h1>
          <span class="font-semibold">Email Sent</span>
        </div>
      </router-link>
      <!-- // -->
      <router-link
         to="/organization/contact"
        class="border border-gray-200 px-4 py-12 w-1/3 rounded justify-center flex items-center"
      >
        <div class="flex mr-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
        </div>
        <div class="text-center">
          <h1 class="text-2xl font-bold text-green-900">10,000</h1>
          <span class="font-semibold">Contacts</span>
        </div>
      </router-link>
      <!-- // -->
      <router-link
         to="/organization/contact"
        class="border border-gray-200 px-4 py-12 w-1/3 rounded justify-center flex items-center"
      >
        <div class="flex mr-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            />
          </svg>
        </div>
        <div class="text-center">
          <h1 class="text-2xl font-bold text-green-900">100,000</h1>
          <span class="font-semibold">Api Request</span>
        </div>
      </router-link>
      <!-- end of api request -->
    </div>
    <div class="flex space-x-3 mt-4">
      <div
        class="border border-gray-200 px-4 py-12 w-1/3 rounded justify-center flex items-center"
      >
        <div class="flex mr-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"
            />
          </svg>
        </div>
        <div class="text-center">
          <h1 class="text-2xl font-bold text-green-900">10,000</h1>
          <span class="font-semibold">Hosts</span>
        </div>
      </div>

      <!-- // -->
      <div
        class="border border-gray-200 px-4 py-12 w-1/3 rounded justify-center flex items-center"
      >
        <div class="flex mr-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
            />
          </svg>
        </div>
        <div class="text-center">
          <h1 class="text-2xl font-bold text-green-900">10</h1>
          <span class="font-semibold">Email Template</span>
        </div>
      </div>

      <!-- // -->
      <div
        class="border border-gray-200 px-4 py-12 w-1/3 rounded justify-center flex items-center"
      >
        <div class="flex mr-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
            />
          </svg>
        </div>
        <div class="text-center">
          <h1 class="text-2xl font-bold text-green-900">4</h1>
          <span class="font-semibold">API Source</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "dashboard-page",
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    this.loading = true;
    await this.doGetMyOrganization();
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      myOrganization: "OrganizationStore/myOrganization",
    }),
  },
  methods: {
    ...mapActions({
      doGetMyOrganization: "OrganizationStore/doGetMyOrganization",
    }),
  },
};
</script>

<style></style>
