<template>
  <div>
	<router-view />
  </div>
</template>

<script>
export default {
name:'apikey-index'
}
</script>

<style>

</style>