import Api from "../../services/api";
export default {
  namespaced: true,
  state: {
    organizations: [],
    apis: [],
    hosts: [],
    templates: [],
    apikeys: [],
    contacts: [],
    organization: {},
    myOrganization: {},
    message: "",
    error: false,
    color: "success",
  },
  actions: {
    async doGet({ commit }) {
      let response;
      try {
        response = await Api().get(`/organization`);
        if (!response.data.error) {
          commit("SET_ORGANIZATIONS", response.data.record);
        }
      } catch (e) {
        console.log("eror", e);
      }
    },
    async doGetMyOrganization({ commit }) {
      let response;
      try {
        response = await Api().get(`/user/myOrganization`);
        if (!response.data.error) {
          commit("SET_MYORGANIZATION", response.data.record);
        }
      } catch (e) {
        console.log("eror", e);
      }
    },
    async doGetOne({ commit }, id) {
      console.log("id =>" + id);
      let response;
      response = await Api().get(`/organization/${id}`);
      if (!response.data.error) {
        commit("SET_ORGANIZATION", response.data.record);
      }
    },
    async doChangeStatus({ commit, dispatch }, payload) {
      let response;
      try {
        (response = await Api().put(`/organization/${payload.id}/status`, {
          ...payload.data,
        })),
          commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (response.data.error) {
          commit("SET_COLOR", "error");
        } else {
          dispatch("doGet");
        }
      } catch (e) {
        console.log("e=>" + e);
      }
    },
    async doCreate({ commit, dispatch }, payload) {
      console.log("payload =>" + JSON.stringify(payload));
      let response;
      try {
        (response = await Api().post(`/organization/`, { ...payload })),
          commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (response.data.error) {
          commit("SET_COLOR", "error");
        } else {
          dispatch("doGet");
        }
      } catch (e) {
        console.log("e=>" + e);
      }
    },
    // add contact
    async doCreateContact({ commit, dispatch }, payload) {
      let response;
      try {
        (response = await Api().post(`/user/myOrganizationContact`, {
          ...payload,
        })),
          console.log("response in doCreateContact" + JSON.stringify(response));
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (response.data.error) {
          commit("SET_COLOR", "error");
        } else {
          dispatch("doGetContact", payload);
        }
      } catch (e) {
        console.log("e=>" + e);
      }
    },
    async doCreateHost({ commit, dispatch }, payload) {
      let response;
      try {
        (response = await Api().post(`/user/myOrganizationHost`, {
          ...payload,
        })),
          commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (response.data.error) {
          commit("SET_COLOR", "error");
        } else {
          dispatch("doGetHost");
        }
      } catch (e) {
        console.log("e=>" + e);
      }
    },
    // create template
    async doCreateTemplate({ commit, dispatch }, payload) {
      let response;
      try {
        (response = await Api().post(`/user/myOrganizationEmailTemplate`, {
          ...payload,
        })),
          commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (response.data.error) {
          commit("SET_COLOR", "error");
        } else {
          dispatch("doGetTemplate");
        }
      } catch (e) {
        commit("SET_ERROR", true);
        commit("SET_MESSAGE", e);
        console.log("e=>" + e);
      }
    },

    // add api
    async doCreateApi({ commit, dispatch }, payload) {
      let response;
      try {
        (response = await Api().post(`/user/myOrganizationApi`, {
          ...payload,
        })),
          console.log("response=>" + JSON.stringify(response));
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (response.data.error) {
          commit("SET_COLOR", "error");
        } else {
          dispatch("doGetApi");
        }
      } catch (e) {
        console.log("e=>" + e);
      }
    },
    async doGetApi({ commit }) {
      let response;
      try {
        (response = await Api().get(`/user/myOrganizationApi`)),
          console.log("response =>" + response);
        if (response.data.record.length > 0) {
          commit("SET_APIS", response.data.record[0].api);
        }
      } catch (e) {
        console.log("e=>" + e);
      }
    },
    async doGetApiKey({ commit }) {
      let response;
      try {
        (response = await Api().get(`/user/myOrganizationApiKeyToken`)),
          console.log("response =>" + response);
        if (response.data.record.length > 0) {
          commit("SET_APIKEYS", response.data.record[0].apiToken);
        }
      } catch (e) {
        console.log("e=>" + e);
      }
    },
    async doGetHost({ commit }) {
      let response;
      try {
        (response = await Api().get(`/user/myOrganizationHost`)),
          console.log("response =>" + response);
        if (response.data.record.length > 0) {
          commit("SET_HOSTS", response.data.record[0].host);
        }
      } catch (e) {
        console.log("e=>" + e);
      }
    },
    //
    async doGetTemplate({ commit }) {
      let response;
      try {
        (response = await Api().get(`/user/myOrganizationEmailTemplate`)),
          console.log("response =>" + response);
        if (response.data.record.length > 0) {
          commit("SET_TEMPLATES", response.data.record[0].emailTemplate);
        }
      } catch (e) {
        console.log("e=>" + e);
      }
    },
    //
    async doGetContact({ commit }) {
      let response;
      try {
        response = await Api().get(`/user/myOrganizationContact`);
        if (response.data.record.length > 0) {
          commit("SET_CONTACTS", response.data.record[0].contact);
        }
      } catch (e) {
        console.log("e=>" + e);
      }
    },
    toggleSideBarMini({ commit }) {
      commit("TOGGLE_SIDEBAR_MINI");
    },
  },
  getters: {
    message(state) {
      return state.message;
    },
    templates(state) {
      return state.templates;
    },
    error(state) {
      return state.error;
    },

    SideBarMini(state) {
      return state.sidebarMin;
    },
    apis(state) {
      return state.apis;
    },
    apikeys(state) {
      return state.apikeys;
    },
    hosts(state) {
      return state.hosts;
    },
    organizations(state) {
      return state.organizations;
    },
    myOrganization(state) {
      return state.myOrganization;
    },
    organization(state) {
      return state.organization;
    },
    contacts(state) {
      return state.contacts;
    },
  },
  mutations: {
    SET_ORGANIZATIONS(state, payload) {
      state.organizations = payload;
    },
    SET_TEMPLATES(state, payload) {
      state.templates = payload;
    },
    SET_CONTACTS(state, payload) {
      state.contacts = payload;
    },
    SET_APIS(state, payload) {
      state.apis = payload;
    },
    SET_APIKEYS(state, payload) {
      state.apikeys = payload;
    },
    SET_HOSTS(state, payload) {
      state.hosts = payload;
    },
    SET_MYORGANIZATION(state, payload) {
      state.myOrganization = payload;
    },
    SET_ORGANIZATION(state, payload) {
      state.organization = payload;
    },
    SET_MESSAGE(state, payload) {
      state.message = payload;
    },
    SET_ERROR(state, payload) {
      state.error = payload;
    },
    SET_COLOR(state, payload) {
      state.color = payload;
    },
  },
};
