<template>
  <router-view />
</template>

<script>
export default {
	name:'single-o-page'
}
</script>

<style>

</style>