<template>
  <div>
	clients dashoard
  </div>
</template>

<script>
export default {
	name:'client-dashboard'

}
</script>

<style>

</style>