<template>
  <div class=" border-b border-gray-300 py-2.5 px-3.5 relative ">
      <div class="flex justify-between">
      <div>
          Adey-mailer
      </div>
      <div class="flex items-center space-x-2 hover:cursor-pointer" @click="logout"> 
          <div class="bg-red-400 rounded-full px-2">
              <span class="font-bold text-white">T</span>
          </div>
          <span class="text-sm font-semibold hidden md:flex">Tedros Hailu</span>
          <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 " fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
          </span>
      </div>
  </div>
  <div class="hidden absolute right-2 top-11 rounded-md border border-gray-200  flex-col">
      <div class="flex flex-col  items-center px-12 py-2 border-b border-gray-200">
          <div class="bg-blue-400 rounded-full p-px mb-1">
              <img class="rounded-full h-8 w-8" src="https://www.freepsdbazaar.com/wp-content/uploads/2020/06/sky-replace/sun-rise/sunrise-23-freepsdbazaar.jpg" alt="">
          </div>
          <div class="font-semibold text-sm">Tedros Hailu</div>   
          </div>
    
      <div class="flex space-x-2 items-center  px-4 py-3 hover:bg-gray-100 border-b border-gray-200">
          <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
</svg>
          </span>
          <span class="text-sm">
              Logout
          </span>
      </div>
      <div class="flex space-x-2 items-center rounded-b-lg px-4 py-3 hover:bg-gray-100">
          <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
</svg>
          </span>
          <span class="text-sm">
              Logout
          </span>
      </div>
  </div>
  </div>
</template>

<script>
export default {
    name:'topheader-section',
    methods:{
        logout(){
                  localStorage.clear();
      this.$router.push("/");
        }
    }
}
</script>

<style>

</style>