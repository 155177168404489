<template>
  <div>
      email list
  </div>
</template>

<script>
export default {
    name:'emailist-page'
}
</script>

<style>

</style>