<template>
  <div class="flex flex-col px-24">pricing</div>
</template>

<script>
export default {
  name: "pricing-page",
};
</script>

<style></style>
