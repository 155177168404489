import { createStore } from "vuex";
//
import NavStore from "./module/nav";
import UserStore from "./module/user";
import OrganizationStore from "./module/organization";
import EmailListStore from "./module/emailList";
import TemplateStore from "./module/templates";
import ToastStore from "./module/toast";
import ContactStore from "./module/contact";
import HostStore from "./module/host";
import ApiStore from "./module/api";
import AdminStore from "./module/admin";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    NavStore,
    UserStore,
    OrganizationStore,
    EmailListStore,
    TemplateStore,
    ToastStore,
    ContactStore,
    HostStore,
    ApiStore,
    AdminStore,
  },
});
