<template>
  <div class="flex flex-col">
    <!-- <div class="text-white">{{ currentRouteName }}</div> -->
    
    <div class="px-2.5 py-4 text-white">
      
      
      <span class="text-2xl">Adey-mailer</span>
    </div>
    <ul class="w-full">   
        <router-link
         to="/organization"
         :class="isRouteActive(`Odashboard`) ? `bg-active text-white text-sm font-bold border-l-4 border-blue-500 pl-3`:`text-inactive font-medium text-sm pl-4` "
         class="flex  py-3.5
          
          pr-2.5
          
          hover:bg-active
          space-x-2
          items-center">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
          </svg>
          </span>
          <span class=" ">Dashboard</span>
          
        </router-link>
                <router-link
         to="/organization/email"
         :class="isRouteActive(`OEmail`) ? `bg-active text-white text-sm font-bold border-l-4 border-blue-500 pl-3`:`text-inactive font-medium text-sm pl-4` "
          
         class="flex  py-3.5
          
          pr-2.5
          
          hover:bg-active
          space-x-2
          items-center">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
</svg>
          </span>
          <span class=" ">Emails</span>
          
        </router-link>

        <!-- hosts -->
         <router-link
         to="/organization/host"
         :class="isRouteActive(`OHost`) ? `bg-active text-white text-sm font-bold border-l-4 border-blue-500 pl-3`:`text-inactive font-medium text-sm pl-4` "
         class="flex  py-3.5
          
          pr-2.5
          
          hover:bg-active
          space-x-2
          items-center">
          <span>
             <svg xmlns="http://www.w3.org/2000/svg" class="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
</svg>
          </span>
          <span class=" ">Hosts</span>
          
        </router-link>

        <!-- end hosts -->
               <!-- start templates -->
       <router-link
         to="/organization/template"
         :class="isRouteActive(`OTemplate`) ? `bg-active text-white text-sm font-bold border-l-4 border-blue-500 pl-3`:`text-inactive font-medium text-sm pl-4` "
         class="flex  py-3.5
          
          pr-2.5
          
          hover:bg-active
          space-x-2
          items-center">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
          </svg>
          </span>
          <span class=" ">Templates</span>
          
        </router-link>

       <!-- end templates -->
        <!-- client -->
       <!-- start api -->
       <router-link
         to="/organization/api"
         :class="isRouteActive(`OApi`) ? `bg-active text-white text-sm font-bold border-l-4 border-blue-500 pl-3`:`text-inactive font-medium text-sm pl-4` "
         class="flex  py-3.5
          
          pr-2.5
          
          hover:bg-active
          space-x-2
          items-center">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
</svg>
          </span>
          <span class=" ">Apis</span>
          
        </router-link>
         <router-link
         to="/organization/contact"
         :class="isRouteActive(`OContact`) ? `bg-active text-white text-sm font-bold border-l-4 border-blue-500 pl-3`:`text-inactive font-medium text-sm pl-4` "
         class="flex  py-3.5
          
          pr-2.5
          
          hover:bg-active
          space-x-2
          items-center">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
</svg>
          </span>
          <span class=" ">Contacts</span>
          
        </router-link>

       <!-- end apis -->

       <!-- api keys -->
       <router-link
         to="/organization/apikey"
         :class="isRouteActive(`OApiKey`) ? `bg-active text-white text-sm font-bold border-l-4 border-blue-500 pl-3`:`text-inactive font-medium text-sm pl-4` "
         class="flex  py-3.5
          
          pr-2.5
          
          hover:bg-active
          space-x-2
          items-center">
          <span>
           <svg xmlns="http://www.w3.org/2000/svg" class="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
</svg>
          </span>
          <span class=" ">API key</span>
          
        </router-link>
      <!-- end api keys -->

        <router-link to="/organization/setting"
        :class="isRouteActive(`OSetting`) ? `bg-active text-white text-sm font-bold border-l-4 border-blue-500 pl-3`:`text-inactive font-medium text-sm    pl-4` "
         class="flex text-white  py-3.5
          hover:bg-active
          pr-2.5   
          space-x-2
          items-center
          ">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>
          </span>
          <span class=""> Settings</span>
          
        </router-link>
        
          
      
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "sidebar-section",
  computed:{
    ...mapGetters({
      sidebarmini: "NavStore/SideBarMini",
    }),
    currentRouteName() {
        return this.$route.name;
    }
  },
  methods:{
    isRouteActive(routeName){
      return (this.$route.name ===routeName) ? true:false
    }
  }
};
</script>

<style>
</style>