<template>
  <div class="flex h-screen overflow-hidden">
    <side-bar class="bg-sidebar hidden lg:block md:w-1/6 sticky" />
    <div class=" w-full lg:w-5/6 overflow-auto flex flex-col">
      <top-header />
      <router-view class="p-6"/>
    </div>
  </div>
</template>

<script>
import SideBar from "@/layout/SideBar.vue";
import TopHeader from "@/layout/TopHeader.vue";
export default {
  name: "home-page",
  components: {
    SideBar,
    TopHeader
  },
};
</script>

<style>
</style>