<template>
  <div class="flex flex-col">
    <!-- <div class="text-white">{{ currentRouteName }}</div> -->

    <div class="px-2.5 py-4 text-white">
      <span class="text-2xl">Adey-mailer</span>
    </div>
    <ul class="w-full">
      <router-link
        to="/admin"
        :class="
          isRouteActive(`dashboard`)
            ? `bg-active text-white text-sm font-bold border-l-4 border-blue-500 pl-3`
            : `text-inactive font-medium text-sm pl-4`
        "
        class="flex py-3.5 pr-2.5 hover:bg-active space-x-2 items-center"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
          </svg>
        </span>
        <span class=" ">Dashboard</span>
      </router-link>
      <router-link
        to="/admin/organization"
        :class="
          isRouteActive(`organization`)
            ? `bg-active text-white text-sm font-bold border-l-4 border-blue-500 pl-3`
            : `text-inactive font-medium text-sm pl-4`
        "
        class="flex py-3.5 pr-2.5 hover:bg-active space-x-2 items-center"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
            />
          </svg>
        </span>
        <span class=" ">Organizations</span>
      </router-link>

      <!-- <router-link to="/"
         :class="isRouteActive(`dashboard`) ? `bg-active text-white text-sm font-bold `:`text-inactive font-medium text-sm` "
         class="flex text-white  py-2.5
          pl-4
          pr-2.5
          space-x-1.5
          items-center">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
          </svg>
          </span>
          <span class="text-sm font-medium">Home</span>
          
        </router-link> -->
      <router-link
        to="/admin/account"
        :class="
          isRouteActive(`account`)
            ? `bg-active text-white text-sm font-bold border-l-4 border-blue-500 pl-3`
            : `text-inactive font-medium text-sm    pl-4`
        "
        class="flex text-white py-3.5 hover:bg-active pr-2.5 space-x-2 items-center"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </span>
        <span class="">Accout Settings</span>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "sidebar-section",
  computed: {
    ...mapGetters({
      sidebarmini: "NavStore/SideBarMini",
    }),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    isRouteActive(routeName) {
      return this.$route.name === routeName ? true : false;
    },
  },
};
</script>

<style></style>
