<template>
  <div class="flex flex-col px-24">service</div>
</template>

<script>
export default {
  name: "service-page",
};
</script>

<style></style>
