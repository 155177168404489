<template>
  <div class="flex justify-center align-middle">
    <div class="w-1/2 flex flex-col my-auto">
      <h1 class="text-5xl">
        Software Development Company For Your Digital Transformation
      </h1>
      <span class="mt-8"></span>
      <p class="text-lg tracking-wide">
        We deliver web and mobile app development services to global businesses
        since 1998, with 100% project delivery success. Hire the best
        programmers at affordable prices. Our design-focused approach and
        project execution processes help you to deliver the right solutions.
      </p>
      <span class="mt-4"></span>
      <div>
        <button class="bg-primary px-6 py-2 rounded-3xl text-xl">
          What we do
        </button>
      </div>
    </div>
    <div class="w-1/2 flex flex-col my-auto">image here</div>
  </div>
</template>

<script>
export default {
  name: "hero-section",
};
</script>

<style></style>
