<template>
  <div class="flex flex-col space-y-2">
	<div class="flex justify-between">
		<span class="font-semibold">Email List</span>
	</div>
	<div class="flex space-x-3 items-start">
	<div class="border-y border-gray-200 flex flex-col space-y-3 w-1/2 relative">
		<div class="flex flex-wrap ">
			<div v-for="(email,index) in checkedEmails" :key="index" class="p-2 ">
			<div class="flex rounded border border-gray-500 p-2 items-center space-x-2">
			<span class="text-sm">{{email}}</span>
			<span class="cursor-pointer" @click="removeEmail(email)">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
			</span>
			</div>
			</div>
		</div>
		
		<div v-for="(email,index) in emails" :key="index" class="border border-gray-200 p-4 rounded-md" >
			<div class="flex px-10 justify-between ">
				<input type="checkbox" :value="email.email" v-model="checkedEmails" @click="check($event)"/>
				<span>{{email.firstName}} {{email.lastName}} </span>
				<span>{{email.email}}</span>
			</div>
			
		</div>
		
		</div>
		<div class="flex flex-col w-1/2 space-y-3 px-10 ">
			<div class="border-b border-gray-200 py-4 space-y-3">
			<div class="flex flex-col space-y-1">
				<div>Host </div>
				<div class="w-full">
					<!-- {{emailSendSchema.hostId}} -->
				<select class="w-full appearance-none px-3 py-1.5 text-base text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:border-sky-500 focus:ring-sky-50 focus:outline-none focus:ring-1" v-model="emailSendSchema.hostId">
				
					<option v-for="(host,index) in hosts" :key="index" :value="host._id">{{host.name}}</option>
				</select>
				</div>
			</div>
			<div class="flex flex-col">
				<div>Template</div>
				<div class="w-full">
					<!-- {{emailSendSchema.templateId}} -->
					<select class="w-full appearance-none px-3 py-1.5 text-base text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:border-sky-500 focus:ring-sky-50 focus:outline-none focus:ring-1" v-model="emailSendSchema.templateId">
					<option class="p-2" v-for="(template,index) in templates" :key="index" :value="template._id">{{template.name}}</option>
				</select>
				</div>	
				</div>
				</div>
				<!-- form -->
				<div class="flex flex-col space-y-3">
				<div class="flex flex-col space-y-1">
				<span>Subject</span>
				<input type="email" v-model="emailSendSchema.email.subject"  class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Product meeting" />
			</div>
			<div class="flex flex-col space-y-1">
				<span>Body</span>
				<textarea type="email" v-model="emailSendSchema.email.text" class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Hey team." />
				
			</div>
			<!-- <div v-if="checkedEmails.length > 0">Eds</div> -->
			<button @click="btnsendMessage" class="border border-green-300 py-2 rounded bg-green-50"><span>Send</span> </button>
				</div>

		</div>
		<!-- <div class="flex flex-col w-1/3 bg-red-400 p-5 space-y-3">
			<div>
				<span>Subject</span>
				<input type="text" v-model="email.subject" />
			</div>
			<div>
				<span>Text</span>
				<input type="text" v-model="email.text" />
			</div>
			<div v-if="checkedEmails.length > 0">Eds</div>
			<button @click="btnsendMessage" class="bg-green-300">Send </button>
		</div> -->
	</div>
	
  </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
export default {
	name:'email-dashboard',
	data(){
		return {
			checkedEmails:[],
			emailSendSchema:{
				hostId:'',
				templateId:'',
				email:{
				subject:'',
				text:'',
			}
			},
			
		}
	},
	async created(){
		await this.doGet(),
		await this.doGetTemplate(),
		await this.doGetHost();
	},
		computed:{
		...mapGetters({
            message:'OrganizationStore/message',
            error:'OrganizationStore/error',
            color:'OrganizationStore/color',
			emails:'EmailListStore/emails',
			templates: "OrganizationStore/templates",
			hosts:'OrganizationStore/hosts',
        }),
		},
		methods:{
		...mapActions({
            doGet:'EmailListStore/doGetAll',
			doGetTemplate: "OrganizationStore/doGetTemplate",
			doGetHost:'OrganizationStore/doGetHost',
			sendMessage:'EmailListStore/sendMessage',

        }),
			async btnsendMessage(){
				console.log("this is sample");
				let payload ={
					from : "info@samaritansc.com",
					to : this.checkedEmails,
					subject:this.email.subject,
					text:this.email.text,
				}
				await this.sendMessage(payload)
				
			},
			removeEmail(email){
				const index = this.checkedEmails.indexOf(email);
				if (index > -1) {
    this.checkedEmails.splice(index, 1);
}
			
			},
			check(e) {
				console.log("e=>"+e);
				if (e.target.checked) {
					console.log(e.target.value)
				}
				},
		}

}
</script>

<style>

</style>