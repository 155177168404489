<template>
  <div class="flex flex-col">
    <div class="flex justify-end mb-3">
      <div class="flex border border-blue-200 rounded-lg">
      <button class="px-5 py-1 border-r rounded-l-lg border-blue-300 bg-green-200 text-sm">Total</button>
      <button class="px-5 py-1 border-r border-blue-300  text-sm">Today</button>
      <button class="px-5 py-1 border-r border-blue-300  text-sm">This Week</button>
      <button class="px-5 py-1 border-r border-blue-300  text-sm">This Month</button>
      <button class="px-5 py-1 rounded-r-lg   text-sm">This Year</button>
      </div>
    </div>
      <div class="flex space-x-3">
    <div class="border border-gray-200 px-4  py-6 w-1/3 rounded justify-center flex items-center ">
		<div class="flex mr-6">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
      </svg>
		</div>
		<div class=" text-center">
		<h1 class="text-2xl font-bold text-green-900">10</h1>
		<span class="font-semibold">Organizations</span>
		</div>
	</div>

      <div class="border border-gray-200 px-4  py-6 w-1/3 rounded justify-center flex items-center ">
		<div class="flex mr-6">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
</svg>
		</div>
		<div class=" text-center">
		<h1 class="text-2xl font-bold text-green-900">10,000</h1>
		<span class="font-semibold">Email Sent</span>
		</div>
	</div>

       <div class="border border-gray-200 px-4  py-6 w-1/3 rounded justify-center flex items-center ">
		<div class="flex mr-6">
		<svg xmlns="http://www.w3.org/2000/svg" class="h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
</svg>
		</div>
		<div class=" text-center">
		<h1 class="text-2xl font-bold text-green-900">20,000</h1>
		<span class="font-semibold">api Request</span>
		</div>
	</div>
      </div>
  </div>
</template>

<script>

export default {
    name:'dashboard-page',
    components:{
      
    }

}
</script>

<style>

</style>