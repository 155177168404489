import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/protected/home.vue";
import Index from "@/views/public/index.vue";
import dashboard from "@/views/protected/dashboard.vue";
import PublicHome from "@/views/public/home.vue";
import emaillist from "@/views/protected/emaillist.vue";
import account from "@/views/protected/account.vue";

import login from "@/views/public/login.vue";
import ServicePage from "@/views/public/service.vue";
import PricePage from "@/views/public/pricing.vue";
import ContactUsPage from "@/views/public/contactUs.vue";
//organization
import organizationIndex from "@/views/protected/organization/index.vue";
import organizationDashboard from "@/views/protected/organization/dashboard.vue";
// import organization from '@/views/protected/organization.vue'
import singleOrganizationIndex from "@/views/protected/organization/single/index.vue";
import singleOrganizationDashboard from "@/views/protected/organization/single/dashboard.vue";

// organization admin
import OrganizationAdminIndex from "@/views/organizationAdmin/index.vue";
import OrganizationAdminDashboard from "@/views/organizationAdmin/dashboard.vue";

//
import OApiIndex from "@/views/organizationAdmin/api/index.vue";
import OApiDashboard from "@/views/organizationAdmin/api/dashboard.vue";

import OEmailIndex from "@/views/organizationAdmin/email/index.vue";
import OEmailDashboard from "@/views/organizationAdmin/email/dashboard.vue";

import OHostIndex from "@/views/organizationAdmin/host/index.vue";
import OHostDashboard from "@/views/organizationAdmin/host/dashboard.vue";

import OSettingIndex from "@/views/organizationAdmin/setting/index.vue";
import OSettingDashboard from "@/views/organizationAdmin/setting/dashboard.vue";

import OTemplateIndex from "@/views/organizationAdmin/template/index.vue";
import OTemplateDashboard from "@/views/organizationAdmin/template/dashboard.vue";

import OClientIndex from "@/views/organizationAdmin/clients/index.vue";
import OClientDashboard from "@/views/organizationAdmin/clients/dashboard.vue";

import OContactIndex from "@/views/organizationAdmin/contact/index.vue";
import OContactDashboard from "@/views/organizationAdmin/contact/dashboard.vue";

import OApiKeyIndex from "@/views/organizationAdmin/apikey/index.vue";
import OApiKeyDashboard from "@/views/organizationAdmin/apikey/dashboard.vue";

const routes = [
  {
    path: "/",
    component: Index,
    children: [
      {
        path: "",
        name: "home",
        component: PublicHome,
      },
      {
        path: "service",
        name: "service",
        component: ServicePage,
      },
      {
        path: "price",
        name: "price",
        component: PricePage,
      },
      {
        path: "contact",
        name: "contact",
        component: ContactUsPage,
      },
      {
        path: "login",
        name: "login",
        component: login,
      },
    ],
  },
  {
    path: "/admin",
    component: Home,
    children: [
      {
        path: "",
        name: "dashboard",
        component: dashboard,
      },
      {
        path: "email",
        name: "email",
        component: emaillist,
      },
      {
        path: "account",
        name: "account",
        component: account,
      },
      {
        path: "organization",
        component: organizationIndex,
        children: [
          {
            path: "",
            name: "organization",
            component: organizationDashboard,
          },
          {
            path: ":organizationId",
            component: singleOrganizationIndex,
            children: [
              {
                path: "",
                name: "SingleOrganization",
                component: singleOrganizationDashboard,
              },
            ],
          },
        ],
      },

      //
    ],
  },
  {
    path: "/organization",
    component: OrganizationAdminIndex,
    children: [
      {
        path: "",
        name: "Odashboard",
        component: OrganizationAdminDashboard,
      },
      {
        path: "api",
        component: OApiIndex,
        children: [
          {
            path: "",
            name: "OApi",
            component: OApiDashboard,
          },
        ],
      },
      {
        path: "email",
        component: OEmailIndex,
        children: [
          {
            path: "",
            name: "OEmail",
            component: OEmailDashboard,
          },
        ],
      },
      {
        path: "template",
        component: OTemplateIndex,
        children: [
          {
            path: "",
            name: "OTemplate",
            component: OTemplateDashboard,
          },
        ],
      },
      {
        path: "host",
        component: OHostIndex,
        children: [
          {
            path: "",
            name: "OHost",
            component: OHostDashboard,
          },
        ],
      },
      {
        path: "setting",
        component: OSettingIndex,
        children: [
          {
            path: "",
            name: "OSetting",
            component: OSettingDashboard,
          },
        ],
      },
      {
        path: "client",
        component: OClientIndex,
        children: [
          {
            path: "",
            name: "OCLient",
            component: OClientDashboard,
          },
        ],
      },
      {
        path: "contact",
        component: OContactIndex,
        children: [
          {
            path: "",
            name: "OContact",
            component: OContactDashboard,
          },
        ],
      },
      {
        path: "apikey",
        component: OApiKeyIndex,
        children: [
          {
            path: "",
            name: "OApiKey",
            component: OApiKeyDashboard,
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
