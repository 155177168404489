<template>
  <div class="flex flex-col h-screen ">
      <topbarVue />
      <section class="py-12">
        <router-view />
      </section>
  </div>
</template>

<script>
import topbarVue from "@/layout/public/topbar.vue"
export default {
  name:'index-page',
  components:{
    topbarVue
  }

}
</script>

<style>

</style>