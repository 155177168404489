import Api from "../../services/api";
export default {
  namespaced: true,
  state: {
    contacts: [],
    message: "",
    error: false,
    color: "success",
  },
  actions: {
    async doGetAll({ commit }, id) {
      let response;
      try {
        response = await Api().get(`/organization/${id}/contact`);
        // console.log("response =>" + response);
        if (!response.data.error) {
          commit("SET_CONTACTS", response.data.record);
        }
      } catch (err) {
        // console.log('error =>' + err);
      }
    },

    async doCreate({ commit, dispatch }, payload) {
      let response;
      try {
        (response = await Api().post(`/organization/${payload.id}/contact`, {
          ...payload.data,
        })),
          console.log(response.data);
        commit("SET_MESSAGE", response.data.message);
        commit("SET_ERROR", response.data.error);
        if (response.data.error) {
          commit("SET_COLOR", "error");
        } else {
          dispatch("doGetAll", payload.id);
        }
      } catch (e) {
        console.log("e=>" + e);
      }
    },
  },
  getters: {
    contacts(state) {
      return state.contacts;
    },
    message(state) {
      return state.message;
    },
    error(state) {
      return state.error;
    },
    color(state) {
      return state.color;
    },
  },
  mutations: {
    SET_CONTACTS(state, payload) {
      state.contacts = payload;
    },
    SET_MESSAGE(state, payload) {
      state.message = payload;
    },
    SET_ERROR(state, payload) {
      state.error = payload;
    },
    SET_COLOR(state, payload) {
      state.color = payload;
    },
  },
};
