<template>
<div class="p-4  ">
  <div class="flex space-x-3  ">
	<div class="border border-gray-300 px-4  py-6 w-1/3 rounded justify-center flex items-center ">
		<div class="flex mr-6">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-8 " fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
			<path stroke-linecap="round" stroke-linejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
			</svg>
		</div>
		<div class=" text-center">
		<h1 class="text-2xl font-bold text-green-900">134,189</h1>
		<span class="font-semibold">Subscribers</span>
		</div>
	</div>
	<div class="border border-gray-300 p-4 w-1/3 rounded justify-center flex items-center ">
		<div class="flex mr-6">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
			<path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
			</svg>
		</div>
		<div class=" text-center">
		<h1 class="text-2xl font-bold text-green-900">6</h1>
		<span class="font-semibold">Email Templates</span>
		</div>
	</div>
		<div class="border border-gray-300 p-4 w-1/3 rounded justify-center flex items-center ">
		<div class="flex mr-6">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
			<path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
			</svg>
		</div>
		<div class=" text-center">
		<h1 class="text-2xl font-bold text-green-900">1,689,098</h1>
		<span class="font-semibold">Email Sent</span>
		</div>
	</div>

  </div>
  </div>
</template>

<script>
export default {
	name:'dashboard-tab'

}
</script>

<style>

</style>