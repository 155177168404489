export default {
    namespaced: true,
    state: {
        sidebarMin: true
    },
    actions: {
        toggleSideBarMini({ commit }) {
            commit('TOGGLE_SIDEBAR_MINI')
        }
    },
    getters: {
        SideBarMini(state) {
            return state.sidebarMin
        }
    },
    mutations: {
        TOGGLE_SIDEBAR_MINI(state) {
            state.sidebarMin = !state.sidebarMin
        }
    }
}